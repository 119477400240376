import './App.css'
import './css/Header.css'
import "./css/Footer.css"

import React, { useContext, useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";

import {
  Container,
  Navbar,
  Nav,
  // NavDropdown,
  FormControl,
  InputGroup,
  Offcanvas,
  Modal,
  Button,
  FloatingLabel,
  Form
} from "react-bootstrap"

import swal from '@sweetalert/with-react'

import { AppContext, AppProvider } from "./providers/AppContext"

import logo from "./assets/logo.png"
import logo2 from "./assets/logo2.png"
import menu from "./assets/list.png"
import user from "./assets/profile-user.png"

// import id from "./assets/indonesia.png"
import search from "./assets/loupe.png"

import Home from "./pages/Home"
import Page from "./pages/Page"
import News from "./pages/News"
import Status from "./pages/Status"
import Login from "./pages/Login"
import Otp from "./pages/Otp"
import Topup from "./pages/Topup"
import Register from "./pages/Register"
import ForgotPassword from "./pages/ForgotPassword"
import SetNewPassword from "./pages/SetNewPassword"
import DetailProduct from "./pages/DetailProduct"
import ResellerForm from "./pages/ResellerForm"
import Profile from "./pages/Profile"

import tawkTo from "tawkto-react";
const tawkToPropertyId = '619a76876bb0760a49439fc1'
const tawkToKey = '7c974dc2c6930ed9aefa87cbf89e1adb3e67bbf7'


const BrandLogo = () => {

  const history = useHistory()

  return(
    <Navbar.Brand onClick={()=>history.push("/")} className="me-auto pointer">
        <img
          alt="logo"
          src={logo}
          width="30"
          height="30"
          className="logo_icon"
        />
        <img
          alt="logo kalbar game store"
          src={logo2}
          width="300"
          height="26.11"
          className="logo_kgs"
        />
    </Navbar.Brand>
  )
}

const App = () => {

    const [open,setOpen] = React.useState(false)
    const [show,setShow] = React.useState(false)

    const LogUser = (props) => {

        const {login,setLogin} = useContext(AppContext)

        useEffect(()=>{
          const user = localStorage.getItem("user")
          setLogin( user !== null )
        },[setLogin])

        if(login){
          return(<Navigation text="Logout" path="logout" />)
        }
        return(<Navigation text="Login" path="/login" />)
    }


    const Navigation = (props) => {

      const history = useHistory()

      const {setLogin} = useContext(AppContext)

      const navigateTo = (path) => {
        setOpen(false)
        if(path === "logout"){
          localStorage.removeItem("user")
          localStorage.removeItem("profile_pict")
          setLogin(false)
          history.push("/login")
          return;
        }
        history.push(path)
      }

      return(
        <a 
          href={()=>false}
          role="button"
          onClick={()=>navigateTo(props.path)}>
          {props.text}
        </a>
      )
    }

    const ProfileAvatar = (props) => {

      const history = useHistory()
      const pict = localStorage.getItem("profile_pict")
      const profile_pict = pict === null ? user : pict

      return(
        <a
          href={()=>false} 
          role="button"
          onClick={()=>history.push("/profile")}
        >
          <img className="avatar" alt="profile" src={profile_pict} /> 
        </a>
      )
    }

    const searchField = useRef()

    useEffect(()=>{
      if(show) searchField.current.focus()
    },[show])

    const SearchGame = (props) => {

        const {GameData} = useContext(AppContext)

        const history = useHistory()

        const [all_game,setAllGame] = useState([])
        const [search,setSearch] = useState("")

        const searchingGames = async () => {
          const input = {
              search:search
          }
          const result = await GameData(input)
          if(!result.status) return swal("","check your connection","info")
          setAllGame(result.data)
        }

        return(
          <Modal
                show={show}
                onHide={()=>setShow(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel
                        label={"Tulis game yang anda cari..."}
                    >
                        <Form.Control 
                            ref={searchField}
                            placeholder={"Tulis game yang anda cari..."}
                            name="search"
                            required
                            onChange={(e)=>setSearch(e.target.value)}
                            onKeyDown={(e)=>{
                              if (e.key === 'Enter') {
                                  searchingGames()
                              }
                          }}
                        />
                        <Form.Control.Feedback type="invalid">Please fill this field</Form.Control.Feedback>
                    </FloatingLabel>
                    <div className="game_list">
                          {
                            all_game.length === 0 ?
                            <p className="game_kosong">Silahkan melakukan pencarian ulang dengan keyword lain...</p>
                            :
                            all_game.map((item,index)=>{
                              return(<div
                                onClick={()=>{
                                  history.push("/games/"+item.slug)
                                  setShow(false)
                                }
                                }  
                                className="game_search_item"
                                key={"game_"+index.toString()}
                              >
                                <img src={item.logo} alt={"games - "+item.name} />
                                <div className="detail">
                                  <h5>{item.name}</h5>
                                  <p>Dilihat : {item.views}</p>
                                </div>
                              </div>)
                            })
                          }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setShow(false)}>
                        Tutup
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    useEffect(() => {
      tawkTo(tawkToPropertyId, tawkToKey)
    }, [])

    return (
      <AppProvider>
        <Router>
          <div className="body">
            <SearchGame />
            <Navbar variant="custom">
                <Container>
                    <Navbar.Brand 
                    className="pointer"
                    onClick={()=>setOpen(true)} >
                        <img
                        alt="menu"
                        src={menu}
                        width="30"
                        height="30"
                        className=""
                        />
                    </Navbar.Brand>
                    <BrandLogo />
                    <Nav className="custom_menu">
                        <InputGroup className="search_container">
                            <FormControl
                                placeholder="Pencarian game..."
                                className="search"
                                readOnly
                                onClick={()=>setShow(true)}
                            />
                            <InputGroup.Text className="container_logo_search">
                                <img onClick={()=>setShow(true)} src={search} alt="search_logo" />
                            </InputGroup.Text>
                        </InputGroup>
                        <ProfileAvatar />
                    </Nav>
                </Container>
                <Offcanvas
                    show={open}
                    onHide={()=>setOpen(false)}
                >
                    <Offcanvas.Header closeButton closeVariant="white">
                        <Offcanvas.Title>KALBAR GAME STORE</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ul className="sidemenu">
                            <li>
                                <Navigation text="Beranda" path="/" />
                            </li>
                            <li>
                                <Navigation text="Tentang Kami" path="/pages/tentang-kami" />
                            </li>
                            <li>
                                <Navigation text="Syarat dan Ketentuan" path="/pages/syarat-dan-ketentuan" />
                            </li>
                            <li>
                                <Navigation text="Program Reseller" path="/pages/program-reseller" />
                            </li>
                            <li>
                              <LogUser />
                            </li>
                        </ul>
                        <p className="footer_offcanvas">Copyright © 2021 - KALBAR GAME STORE</p>
                    </Offcanvas.Body>
                </Offcanvas>
            </Navbar>

            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/otp">
                <Otp />
              </Route>
              <Route exact path="/topup">
                <Topup />
              </Route>
              <Route exact path="/status/:slug">
                <Status />
              </Route>
              <Route exact path="/set-new-password/:slug">
                <SetNewPassword />
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route exact path="/register">
                <Register />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/profile">
                <Profile />
              </Route>
              <Route exact path="/form/:name">
                <ResellerForm />
              </Route>
              <Route exact path="/games/:slug">
                <DetailProduct />
              </Route>
              <Route path="/pages/:slug">
                <Page />
              </Route>
              <Route path="/news/:slug">
                <News />
              </Route>
            </Switch>

            <div className="footer">
                <p>Copyright © 2021 - KALBAR GAME STORE</p>
                <ul className="footer_menu">
                    <li>
                      <Link to="/pages/syarat-dan-ketentuan">Syarat dan Ketentuan</Link>
                    </li>
                    <li>
                      <Link to="/pages/kebijakan-dan-privasi">Kebijakan & Privasi</Link>
                    </li>
                    <li>
                      <Link to="/pages/hubungi-kami">Hubungi Kami</Link>
                    </li>
                </ul>
            </div>
          </div>
        </Router>
      </AppProvider>
    );
}

export default  App