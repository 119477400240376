import "../css/Details.css"
import { useState,useEffect,useContext,useCallback } from "react"
import { Row,Container, Col,FloatingLabel,Form,Button,Modal,Spinner } from "react-bootstrap"
import swal from '@sweetalert/with-react';

import info from "../assets/info.png"
import active1 from "../assets/check1.png"
import active2 from "../assets/check2.png"
import gplay from "../assets/gplay.png"
import appstore from "../assets/appstore.png"

import {  useParams,
    // useHistory
} from "react-router-dom"
import { AppContext } from "../providers/AppContext"
import Helper from "../Helper"
import LoadingScreen from "../components/LoadingScreen"


const DetailProduct = (props) => {

    // const history = useHistory()
    const params = useParams();

    const [game,setGame] = useState({
        "name": "GAME NAME",
        "logo": "https://via.placeholder.com/483x483.png",
        "description": "-"
    })
    const [category,setCategory] = useState([])

    const {DetailData,SubmitTransaction} = useContext(AppContext)

    const [inputdata,setInputData] = useState([])

    // const ahref = useRef(null)
    // const [redirect_url,setRedirectUrl] = useState("https://google.com")

    const [loadingPage,setLoadingPage] = useState(true)

    const loadHarga = (harga) => {
        const new_payment_opt = payment_opt.map((item,index)=>{
            let price = parseInt(harga);
            const percent = price * parseFloat(item.fee_percent) ;
            const fee = parseInt(item.fee);
            price = price + fee + percent;
            item.price = parseInt(price);
            price = Helper.thousands_separators(price.toFixed(0));
            item.price_text = price;
            return item;
        })
        new_payment_opt.sort(compare)
        setPaymentOpt(new_payment_opt)
    }

    const [payment_opt,setPaymentOpt] = useState([])

    useEffect(()=>{
        setAtiveCategory(0)
        const data = category[0]
        if(data === undefined) {
            return;
        }
        setCategoryData(data)
        setProduct(data.product)
        const field = JSON.parse(data.field_type);
        setInputData(field)
        setProductData(null)
    },[category])

    const [mobile,setMobile] = useState({
        "gplay": "https://kalbargamestore.com",
        "appstore": "https://kalbargamestore.com",
      })
    
    const fetchData = useCallback(async () => {
        let user = localStorage.getItem("user");
        if(user?.no_hp){
            const regex = /[^0-9+]+/g;
            const hp = user.no_hp.replace( regex, "");
            setPhone(hp)
        }
        // let trx = localStorage.getItem("last_trx");
        // if(trx !== undefined && trx !== null) {
        //     trx = JSON.parse(trx);
        //     history.push("/status/"+trx.trx_id)
        // }
        const {slug} = params;
        if(slug === undefined) swal("","game not found","info")
        const data = {game:slug}
        setLoadingPage(true)
        const result = await DetailData(data)
        setLoadingPage(false)
        if(!result.status) return swal("","check your connection","info")
        setMobile(result.data.config)
        setCategory(result.data.category)
        setGame(result.data.game)
        setPaymentOpt(result.data.payment)

        document.title = result.data.game.name+" - Kalbar Game Store"
        document.querySelector('meta[name="og:title"]').setAttribute("content", result.data.game.name);

        const images = result.data.game.logo === null ? "/news.jpeg" : result.data.game.logo;
        document.querySelector('meta[name="og:image"]').setAttribute("content", images);


    },[DetailData,params,
        // history
    ])

    useEffect( () => {

        fetchData()

    },[fetchData])

    const [active_category,setAtiveCategory] = useState(null)

    const [categoryData,setCategoryData] = useState({
        instruction:"",
        notes:"",
    });
    const [product,setProduct] = useState([]);
    
    const selectCategory = (index) => {
        setAtiveCategory(index)
        const data = category[index]
        if(data === undefined) swal("","category not found","info")
        setCategoryData(data)
        setProduct(data.product)
        const field = JSON.parse(data.field_type);
        setInputData(field)
        productSelect(null)
    }

    const [productActive,setProductActive] = useState(null)
    const [productData,setProductData] = useState(null)

    const productSelect = (index) => {
        setProductActive(index)
        const data = product[index]
        if(data === undefined) {
            setProductData(index)
            loadHarga(0)
            return;
        }
        setProductData(data)
        let user = localStorage.getItem("user")
        let harga = data.end_price
        if(user !== undefined && user !== null) {
            user = JSON.parse(user)
            switch (user.role) {
                case "langganan":
                    harga = data.subs_price
                break;
                case "reseller":
                    harga = data.reseller_price
                break;
                default:
                    harga = data.end_price
                break;
            }
        }
        loadHarga(harga)
    }

    const Product = (props) => {
        const {data} = props;
        const active = props.active ? "active" : ""
        return(
            <div 
                onClick={()=>productSelect(props.index)} 
                className={"product_container "+active}
            >
                {
                    active ?
                    <img className="active_icon" src={active1} alt="active" />
                    :
                    <div></div>
                }
                {
                    data.bestseller !== undefined ?
                    <img className="promo" alt="bestseller" src={data.bestseller} />
                    :
                    <div></div>
                }
                {
                    data.discount !== undefined ?
                    <img className="promo" alt="discount" src={data.discount} />
                    :
                    <div></div>
                }
                <div className="info">
                    <p className="game_name">{game.name}</p>
                    <p className="product_name">{data.name}</p>
                </div>
                <img className="product_icon" alt="product" src={categoryData.logo} />
            </div>
        )
    }

    const [paymentActive,setPaymentActive] = useState(null)
    const [payment,setPayment] = useState(null)

    const selectPayment = (index) => {
        setPaymentActive(index)
        const data = payment_opt[index]
        if(data === undefined) swal("","not found","info")
        setPayment(data)
    }

    const Payment = (props) => {
        const {data} = props
        const active = props.active ? "active" : ""
        data.price = data.price === undefined ? 0 : data.price
        return(
            <div 
                onClick={()=>selectPayment(props.index)}
                className={"payment_container "+active}
            >
                {
                    active ?
                    <img className="active_icon" src={active2} alt="active" />
                    :
                    <div></div>
                }
                <div className="info">
                    <img className="payment_icon" src={data.logo} alt="payment_icon" />
                    <span>{data.name}</span>
                </div>
                <p>Rp. {data.price_text},-</p>
            </div>
        )
    }

    const [validated, setValidated] = useState(false);
    const [load,setLoad] = useState(false)

    // useEffect(()=>{
    //     console.log("redirect_url>>",redirect_url)
    //     ahref?.current?.click();
    // },[redirect_url])

    const submit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        let check = {status:true,message:""};
        if(payment === null) {
            check.status = false;
            check.message = "Silahkan pilih metode pembayaran";
        }
        if(productData === null) {
            check.status = false;
            check.message = "Silahkan pilih nominal produk";
        }
        if(categoryData.name === undefined) {
            check.status = false;
            check.message = "Silahkan pilih jenis yang ingin di topup";
        }
        check.status = form.checkValidity();
        setValidated(true)
        if (check.status === false) {
            if(check.message !== "") swal("",check.message,"info")
            event.stopPropagation();
            return;
        }
        const formData = new FormData(form);
        let input = {}
        for (let [key, value] of formData.entries()) {
            input[key] = value;
        }
        let destination = "";
        inputdata.map((item,index)=>{
            destination += input[item.name]
            return false
        })
        input.destination = destination;
        input.variant_id = productData.id;
        input.payment_id = payment.id;
        setLoad(true)
        const result = await SubmitTransaction(input)
        setLoad(false)
        if(!result.status) return swal("","check your connection","info")
        if(!result.data.status) return swal("",result.data.message,"info")
        const last_trx = {trx_id:result.data.trx_id,via:payment.id,url:result.data.data.va}
        localStorage.setItem("last_trx",JSON.stringify(last_trx))
        console.log(result.data.data.va);
        return window.location.href = result.data.data.va;
    }

    const [show,setShow] = useState(false)

    const [phone,setPhone] = useState("")

    const compare = ( a, b ) => {
        if ( a.price < b.price ){
          return -1;
        }
        if ( a.price > b.price ){
          return 1;
        }
        return 0;
    }

    if(loadingPage)
    return(<LoadingScreen />)

    return(
        <Container fluid className="container_details">
            <Modal
                show={show}
                onHide={()=>setShow(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Instruksi Bantuan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {categoryData.instruction}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setShow(false)}>
                        Tutup
                    </Button>
                </Modal.Footer>
            </Modal>
            <Form noValidate validated={validated} onSubmit={submit}>
                <Row>
                    <Col md={4} className="games_info" >
                        <div 
                            className="image_game"
                            style={{
                                backgroundImage:`url(${game.logo})`
                            }}
                        />
                        <div className="summary">
                            <h3>{game.name}</h3>
                            <p>{game.description}</p>
                            <a href={mobile.gplay}>
                                <img 
                                    className="mobile_store"
                                    alt="google play store" 
                                    src={gplay}
                                />
                            </a>
                            <a href={mobile.appstore}>
                                <img 
                                    className="mobile_store"
                                    alt="apps store" 
                                    src={appstore}
                                />
                            </a>
                        </div>
                    </Col>
                    <Col md={8} className="form_id">
                        <div className="category_section section">
                            <p>Silakan pilih salah satu</p>
                            <div className="category_list">
                                {
                                    category.map((item,index)=>{
                                        const active = index === active_category ? "active": "";
                                        return(
                                            <div 
                                                onClick={()=>selectCategory(index)}
                                                key={"category_data_"+index.toString()}
                                                className={"category_item "+active}
                                            >
                                                {item.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="category_topup section">
                            <p>TOPUP GAME</p>
                            <div className="topup_section">
                                <div className="step first_step">
                                    <div className="step_icon">1</div>
                                    <p className="step_name">Masukkan Player ID <img className="info" onClick={()=>setShow(true)} src={info} alt="info" /> </p>
                                    <div className="detail_player">
                                        {
                                            inputdata.map((item,index)=>{
                                                    return(<FloatingLabel
                                                        key={"input_data_"+index.toString()}
                                                        label={item.placeholder}
                                                    >
                                                        <Form.Control 
                                                            placeholder={item.placeholder}
                                                            name={item.name}
                                                            required={item.rules.required}
                                                            type={item.rules.type}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please fill this field</Form.Control.Feedback>
                                                    </FloatingLabel>)
                                            })
                                        }
                                    </div>
                                    <p className="hint-text">{categoryData.notes}</p>
                                </div>
                                <div className="step">
                                    <div className="step_icon">2</div>
                                    <p className="step_name">Pilih Nominal Top Up</p>
                                    <div className="product_list">
                                        {
                                            product.length === 0 ?
                                                <div className="empty_product">Kosong pilihan, silahkan coba lagi kembali...</div>
                                            :
                                            product.map((item,index)=>{
                                                return(
                                                    <Product
                                                        active={index === productActive}
                                                        key={"product_"+index.toString()}
                                                        data={item} 
                                                        index={index}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="step">
                                    <div className="step_icon">3</div>
                                    <p className="step_name">Pilih Metode Pembayaran</p>
                                    <div className="payment_list">
                                        {
                                            payment_opt.map((item,index)=>{
                                                return(
                                                    <Payment 
                                                        active={index === paymentActive}
                                                        key={"payment_"+index.toString()}
                                                        data={item} 
                                                        index={index}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="step last_step">
                                    <div className="step_icon">4</div>
                                    <p className="step_name">Pembelian</p>
                                    <div className="detail_player">
                                        <FloatingLabel
                                            // controlId="floatingInput"
                                            label="No. HP"
                                        >
                                            <Form.Control
                                                name="no_hp"
                                                placeholder="0000"
                                                maxLength="14"
                                                minLength="11"
                                                required
                                                value={phone}
                                                onChange={(event)=>{
                                                    let value = event.target.value;
                                                    const regex = /[^0-9+]+/g;
                                                    value = value.replace( regex, "");
                                                    setPhone(value)
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">Please fill this field, minimum 11 character</Form.Control.Feedback>
                                        </FloatingLabel>
                                        <FloatingLabel
                                            label="Email (opsional)"
                                        >
                                            <Form.Control
                                                name="email"
                                                placeholder="0000"
                                                type="email"
                                            />
                                            <Form.Control.Feedback type="invalid">Check your email format</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                    <div className="d-grid btn_submit">
                                        <Button disabled={load} type="submit" size="lg" variant="success">
                                            {
                                                load ? 
                                                <span>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    {` `} Loading...
                                                </span>
                                                :
                                                <span>Beli Sekarang</span>
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form>
            {/* <a ref={ahref} href={redirect_url}>Redirect</a> */}
        </Container>
    )
}

export default DetailProduct