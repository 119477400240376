import "../css/Form.css"
import {
    Container,
    FloatingLabel,
    Form,
    Button
}  from "react-bootstrap"
import {useParams,useHistory} from "react-router-dom"
import { useContext, useEffect,useState } from "react"
import { AppContext } from "../providers/AppContext"
import swal from '@sweetalert/with-react'

const ResellerForm = () => {

    const {name} = useParams()
    const history = useHistory()
    const [field,setField] = useState([])
    // const [success,setSuccess] = useState("")
    const {FormSaver} = useContext(AppContext)

    useEffect(()=>{
        const reseller = [
            {
                name:"name",
                placeholder:"Nama Lengkap",
                field:"input",
                rules:{
                    required:true,
                    type:"text"
                }
            },
            {
                name:"email",
                placeholder:"Email",
                field:"input",
                rules:{
                    required:true,
                    type:"email"
                }
            },
            {
                name:"no_hp",
                placeholder:"No. HP",
                field:"input",
                rules:{
                    required:true,
                    type:"number",
                    min:0
                }
            },
            {
                name:"link_fb",
                placeholder:"Link FB",
                field:"input",
                rules:{
                    required:false,
                    type:"url"
                }
            },
            {
                name:"referrer",
                placeholder:"Darimanakah anda tahu mengenai reseller program ini?",
                field:"select",
                options:[
                    {value:"Teman atau saudara"},
                    {value:"Sosial Media"},
                    {value:"Influencer"},
                    {value:"Iklan (Ads)"},
                    {value:"Facebook"},
                    {value:"Lainnya"}
                ],
                rules:{
                    required:true
                }
            },
        ]
        setField(reseller)
        // setSuccess("Terima kasih, data anda akan di proses...")
        document.title = name.toUpperCase()+" - Game Store"
    },[name])

    const Input = (props) => {
        const {data} = props;
        return(
            <FloatingLabel
                className="my-3"
                label={data.placeholder}
            >
                <Form.Control 
                    name={data.name}
                    placeholder={data.placeholder}
                    required={data.rules.required}
                    type={data.rules.type}
                    min={data.rules.min ?? false}
                />
            </FloatingLabel>
        )
    }
    const Select = (props) => {
        const {data} = props;
        return(
            <div>
                <p className="mt-3 mb-0">{data.placeholder}</p>
                <Form.Select 
                    name={data.name}
                    required={data.rules.required}
                >
                    <option value="">-- Pilih --</option>
                    {
                        data.options.map((item,index)=>{
                            return(
                                <option value={item.value}>{item.value}</option>
                            )
                        })
                    }
                </Form.Select>
            </div>
        )
    }

    const [validated, setValidated] = useState(false);

    const submit = async (event) => {
        const form = event.currentTarget;
        const formData = new FormData(form);
        event.preventDefault();
        setValidated(true)
        if(!form.checkValidity()) return;
        let field = []
        let val = []
        for (let [key, value] of formData.entries()) {
            field.push(key);
            val.push(value);
        }
        const input = {name:name,field:field,answer:val};
        const result = await FormSaver(input)
        if(!result.status) return swal("","check your connection","info")
        swal("",result.data.message,"info")
        if(result.data.status) history.push("/")
    }


    return(
        <Container>
            <div className="content">
                <h3>{name.toUpperCase()}</h3>
                <Form noValidate validated={validated} onSubmit={submit}>
                    <div className="field_container">
                        {
                            field.map((item,index)=>{
                                switch (item.field) {
                                    case "select":
                                        return(<Select key={"input_"+index.toString()} data={item}/>)       
                                    default:
                                        return(<Input key={"input_"+index.toString()} data={item}/>)
                                }
                            })
                        }
                        <div className="d-grid my-3">
                            <Button type="submit" size="lg">KIRIM</Button>
                        </div>
                    </div>
                </Form>
            </div>
        </Container>
    )
}

export default ResellerForm