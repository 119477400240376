import "../css/Status.css"
import logo  from "../assets/hourglass.png"
import {Button} from "react-bootstrap"
import { useHistory, useParams } from "react-router"
import { useState,useEffect,useContext,useCallback, useRef } from "react"
import { AppContext } from "../providers/AppContext"
import swal from '@sweetalert/with-react'
import LoadingScreen from "../components/LoadingScreen"


import Helper from "../Helper"
const Status = (props) => {

    const history = useHistory()
    const params = useParams()

    const {StatusData,CancelOrder} = useContext(AppContext)

    const [transaction,setTransaction] = useState({
        status:"",
        nominal:0,
        via:"-",
        va:"-",
        payment_id:0,
        api:"va",
        game:"-",
        no_hp:"-",
        instruction:"-"
    })
    
    const [date,setDate] = useState(new Date())
    const [cs_phone,setPhone] = useState(0)

    const [btn_cancel_text,setBCT] = useState("BATALKAN PESANAN");

    const [slug_data,setSlug] = useState(null)
    const [showTimer,setShowTimer] = useState(true)
    const [loadingPage,setLoadingPage] = useState(true)

    const getData = useCallback(async () => {
        if(slug_data === null) return;
        const data = {slug:slug_data}
        const result = await StatusData(data)
        if(!result.status) return swal("","check your connection","info")
        setTransaction(result.data.transaction)
    },[StatusData,slug_data])
     
    const fetchData = useCallback(async () => {
        const {slug} = params;
        if(slug === undefined) swal("","transaction tidak ditemukan","info")
        setSlug(slug)
        setShowTimer(true)
        const data = {slug:slug}
        setLoadingPage(true)
        const result = await StatusData(data)
        setLoadingPage(false)
        if(!result.status) return swal("","check your connection","info")
        setPhone(result.data.transaction.config.cs_phone)
        setTransaction(result.data.transaction)
        const tgl = new Date(result.data.transaction.created_at);
        setDate(tgl)
        document.title = "Transaction status for invoice #"+slug+" - Game Store"
        const expired = new Date(result.data.transaction.expired_at)
        setEnd(expired)
    },[StatusData,params])

    const cancel = async () => {
        const {slug} = params;
        localStorage.removeItem("last_trx")
        const result = await CancelOrder({slug:slug})
        if(!result.status) return swal("","check your connection","info")
        history.push("/")
    }

    const doneOrder = async () => {
        localStorage.removeItem("last_trx")
        history.push("/")
    }

    const timer = useRef()
    const checker = useRef()
    const [end,setEnd] = useState(null)

    const [hour,setHour] = useState(0)
    const [minute,setMinute] = useState(0)
    const [second,setSecond] = useState(0)

    // const cacelData = useCallback(cancel,[CancelOrder,history,params])

    const showRemaining = useCallback(() => {
        
        const _second = 1000;
        const _minute = _second * 60;
        const _hour = _minute * 60;
        const _day = _hour * 24;
    
        const now = new Date();
        const distance = end - now;
        if (end !== null && distance < 0) {
            clearInterval(timer.current)
            setShowTimer(false)
            return;
        }
        const hours = Math.floor((distance % _day) / _hour);
        const minutes = Math.floor((distance % _hour) / _minute);
        const seconds = Math.floor((distance % _minute) / _second);
        setHour(hours)
        setMinute(minutes)
        setSecond(seconds)
    },[end])

    useEffect(()=>{
        if(end !== null){
            timer.current = setInterval(showRemaining, 1000)
        }
        return () => clearInterval(timer.current)
    },[end,showRemaining])

    useEffect( () => {

        checker.current = setInterval(()=>getData(),10000)
        fetchData()
        
        return () => clearInterval(checker.current)
    },[fetchData,getData])

    useEffect(()=>{
        if(transaction.status === "berhasil"){
            clearInterval(timer.current)
            setBCT("SELESAI")
            console.log(timer.current)
        }
    },[transaction])

    if(loadingPage)
    return(<LoadingScreen />)

    return (
        <div className="container_status">
            <div className="content_status">
                <img 
                    className="logo_status"
                    src={logo} 
                    alt="logo"
                />
                <p className="status">{transaction.status.toUpperCase()}</p>
                { 
                    showTimer ? <p className="status_time">{hour} Jam : {minute} Menit : {second} Detik</p> : <div></div>
                }
                <table>
                    <tbody>
                        <tr>
                            <td className="key">TRX ID</td>
                            <td>:</td>
                            <td>{transaction.uid}</td>
                        </tr>
                        <tr>
                            <td>Tanggal</td>
                            <td>:</td>
                            <td>{date.toLocaleDateString()+" "+date.toLocaleTimeString()}</td>
                        </tr>
                        <tr>
                            <td>No. HP</td>
                            <td>:</td>
                            <td>{transaction.no_hp}</td>
                        </tr>
                        <tr>
                            <td>Game</td>
                            <td>:</td>
                            <td>{transaction.game}</td>
                        </tr>
                        <tr>
                            <td>Nominal</td>
                            <td>:</td>
                            <td>Rp. {Helper.thousands_separators(transaction.nominal)},-</td>
                        </tr>
                        <tr>
                            <td>Pembayaran via</td>
                            <td>:</td>
                            <td><img className="via_pembayaran" src={transaction.via} alt={transaction.via} /></td>
                        </tr>
                        <tr>
                            <td className="key">Nomor VA/Kode Pembayaran</td>
                            <td>:</td>
                            <td>
                                {/* <PaymentDisplay active={transaction.api === "qris"} val={transaction.va} /> */}
                                {
                                    transaction.return_as === "image" ?
                                    (<div>
                                        <div className="hidden-mobile"><img alt="qrcode" src={transaction.va} /></div>
                                        <div className="show-mobile"><img alt="mobile qrcode" src={transaction.va} /></div>
                                    </div>)
                                    :
                                    <p>{transaction.va}</p>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Instruksi Pembayaran</td>
                            <td>:</td>
                            <td className="val">{transaction.instruction}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="full_width text-center mobile_action">
                    <Button 
                    onClick={()=>{
                        if(transaction.status === "berhasil"){
                            doneOrder()
                        } else {
                            cancel()
                        }
                    }}
                    className="full_width" variant="cancel_inverted">{btn_cancel_text}</Button>
                    <Button 
                    onClick={()=>{
                        history.push("/")
                    }} 
                    className="full_width back_home" variant="purple">KEMBALI</Button>
                </div>
                <p className="info">Perlu bantuan ? Hubungi <a href={"tel:"+cs_phone}>Customer Service</a> kami.</p>
            </div>
        </div>
    )
}

export default Status