import "../css/Login.css"
import {useContext, useEffect, useState,useCallback} from "react"
import {Button,FloatingLabel,Form,Spinner} from "react-bootstrap"
import { useHistory } from "react-router"
import { AppContext } from "../providers/AppContext"
import swal from '@sweetalert/with-react';

const Login = (props) => {

    const history = useHistory()

    const {DetailData,LoginData} = useContext(AppContext)

    const [config,setConfig] = useState({
        "id": 1,
        "space_iklan_topup": "https://via.placeholder.com/800x1000.png?text=Space%20Iklan",
        "cs_phone": "082255985321",
        "cs_email": "heru.m175@gmail.com",
        "gplay": "https://kalbargamestore.com",
        "appstore": "https://kalbargamestore.com",
        "others": "",
        "created_at": null,
        "updated_at": null
    })

    const fetchData = useCallback(async ()=>{
        const result = await DetailData({})
        if(!result.status) return swal("","check your connection","info")
        setConfig(result.data.config)
    },[DetailData])

    useEffect(()=>{
        document.title = "Login - Game Store";
        let userData = localStorage.getItem("user")
        if(userData !== undefined && userData !== null) return history.push("/profile")
        let user = localStorage.getItem("login")
        if(user !== undefined && user !== null) return history.push("/otp")
    },[history,fetchData])

    const [validated, setValidated] = useState(false);
    const [loading,setLoading] = useState(false)

    const submit = async (event) => {
        const form = event.currentTarget;
        const formData = new FormData(form);
        event.preventDefault();
        setValidated(true)
        if(!form.checkValidity()) {
            return;
        }
        let input = {}
        for (let [key, value] of formData.entries()) {
            input[key] = value;
        }
        setLoading(true)
        const result = await LoginData(input);
        setLoading(false)
        if(!result.status) return swal("","check your connection","info")
        if(!result.data.status) return swal("",result.data.message,"info")
        const {data} = result.data;
        const user = {"email":data.email,"password":data.password}
        localStorage.setItem("login",JSON.stringify(user));
        history.push("/otp")
    }
    return (
        <div className="container_box">
            <div className="transbox">
                <Form noValidate validated={validated} onSubmit={submit}>
                    <div className="content_status_box">
                        <p className="head">Silahkan masuk ke akun anda</p>

                            <FloatingLabel
                                label="Email"
                            >
                                <Form.Control 
                                    placeholder="0000"
                                    required
                                    type="email"
                                    name="email"
                                />
                                <Form.Control.Feedback type="invalid">Please fill this field, check email format</Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel
                                label="Password"
                            >
                                <Form.Control 
                                    placeholder="0000"
                                    required
                                    type="password"
                                    name="password"
                                />
                                <Form.Control.Feedback type="invalid">Please fill this field</Form.Control.Feedback>
                            </FloatingLabel>

                        <Button disabled={loading} type="submit" variant="purple" className="log_btn">
                        {
                            loading ? 
                            <span>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                {` `} Loading...
                            </span>
                            :
                            <span>MASUK</span>
                        }
                        </Button>

                        <div className="action">
                            <Form.Check 
                                type="checkbox"
                                label="Ingat saya"
                            />            
                            <a role="button" href={()=>false} onClick={()=>history.push("/forgot-password")} >Lupa Password</a>
                        </div>
                        <p>Belum punya akun? <a href={() => false} onClick={()=>history.push("/register")} >Daftar Sekarang</a></p>
                        <p className="text-center">
                            Ingin menjadi reseller resmi KALBAR GAME STORE ? <br /> 
                            Hubungi  <a href={"tel:"+config.cs_phone}>Customer Service</a> kami atau <a href={"mailto:"+config.cs_email}>email</a>
                        </p>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default Login