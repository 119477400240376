import "react-responsive-carousel/lib/styles/carousel.min.css"
import {
    Image,
    Button,
    Spinner
} from "react-bootstrap"

import { Carousel } from 'react-responsive-carousel'
import {useHistory} from "react-router-dom"
import { useContext, useEffect,useCallback, useState } from "react"
import { AppContext } from "../providers/AppContext"
import swal from '@sweetalert/with-react';
import LoadingScreen from "../components/LoadingScreen"

const Home = (props) => {

    const history = useHistory()

    const { HomeData, GameData,NewsData } = useContext(AppContext)

    const [slideData,setSlideData] = useState([])
    const [slides,setSlides] = useState([])

    const [populer,setPopuler] = useState([])
    const [all_game,setAllGame] = useState([])
    const [berita,setBerita] = useState([])

    const [loadingPage,setLoadingPage] = useState(true)

    const fetchData = useCallback(async () => {
        setLoadingPage(true)
        const result = await HomeData()
        setLoadingPage(false)
        if(!result.status) return swal("","check your connection","info")
        setSlideData(result.data.slide)
        let slider = result.data.slide.map((item,index)=>{
            return item.pict
        })
        setSlides(slider)
        setPopuler(result.data.populer)
        setAllGame(result.data.all_games)
        setBerita(result.data.news)
        document.title = "Beranda - Kalbar Game Store"
    },[HomeData])

    useEffect( () => {

        fetchData()

    },[fetchData])

    const slideRender = (item) => {
        return (
            <img 
                className="slideshow"
                alt="slideshow"
                src={item} 
            />
        )
    }

    const slideHandler = (index) => {
        const data = slideData[index]
        if(data === undefined) swal("","slide handler not found","info")
        window.location.href = data.url
    }

    const GameRender = ({item}) => {
        return (
        <div className="games_item" onClick={()=>history.push("/games/"+item.slug)}>
            <img 
            alt={item.name+" - game populer"}
            src={item.logo} 
            />
            <p>{item.name}</p>
        </div>
        )
    }

    const PopulerGame = (props) => {
        return(
        <div className="games_container">
            {props.data.map( (item,index) => <GameRender key={"game_"+index} item={item} /> )}
        </div>
        )
    }

    const AllGame = (props) => {
        return(
        <div className="games_container">
            {props.data.map( (item,index) => <GameRender key={"game_"+index} item={item} /> )}
        </div>
        )
    }

    const News = ({item}) => {
        let regex = /(<([^>]+)>)/ig
        , max = 100
        ,   result = item.description.replace(regex, "");
        if(result.length > max) result = result.substring(0,max);
        return(
            <div className="news_item" onClick={()=>{
                history.push("/news/"+item.slug)
            }}>
                <Image 
                    fluid
                    alt="news_1"
                    className="news_image"
                    src={item.images}
                />
                <div className="news_item_detail">
                    <h3>{item.title}</h3>
                    <p>{result}
                    <span>Baca selengkapnya...</span></p>
                </div>
            </div>
        )
    }

    const AllNews = (props) => {
        return(
            <div className="news_container">
            {props.data.map((item,index)=>{
                return( <News key={"news_item_"+index.toString()} item={item} />)
            })}
            </div>
        )
    }

    const [all_game_load,setButtonLoadGame] = useState(true)
    const [loadGame,setLoadGame] = useState(false)
    const moreGames = async () => {
        const data = all_game[all_game.length -1]
        if(data === undefined) return swal("","not found game","info")
        const input = {
            last_id:data.id
        }
        setLoadGame(true)
        const result = await GameData(input)
        setLoadGame(false)
        if(!result.status) return swal("","check your connection","info")
        const new_games = [...all_game,...result.data]
        setAllGame(new_games)
        setButtonLoadGame(result.data.length !== 0)
    }

    const [news_load,setButtonNews] = useState(true)
    const [loadNews,setLoadNews] = useState(false)
    const moreNews = async () => {
        const data = berita[berita.length -1]
        if(data === undefined) return swal("","not found game","info")
        const input = {
            last_id:data.id
        }
        setLoadNews(true)
        const result = await NewsData(input)
        setLoadNews(false)
        if(!result.status) return swal("","check your connection","info")
        const new_data = [...berita,...result.data.list]
        setBerita(new_data)
        setButtonNews(result.data.list.length !== 0)
    }

    if(loadingPage)
    return(<LoadingScreen />)

    return(
        <div>
            {
                slides.length > 0 ? 
                <Carousel
                    autoFocus={true}
                    autoPlay={true}
                    showArrows={true}
                    swipeable={true}
                    emulateTouch={true}
                    showStatus={false}
                    showThumbs={false}
                    infiniteLoop={true}
                    centerMode={true}
                    dynamicHeight={true}
                    renderItem={slideRender}
                    onClickItem={slideHandler}
                >
                    {slides}
                </Carousel>
                : <div/>
            }
            <div className="section_home section_populer">
                <p className="head text-center">POPULER GAMES</p>
                <PopulerGame data={populer} />
            </div>
            <div className="section_home">
                <p className="head all_games">SEMUA GAMES</p>
                <AllGame data={all_game} />
                {
                    all_game_load ?
                    <div className="text-center">
                        <Button disabled={loadGame} onClick={()=>moreGames()} variant="purple">
                            {
                                loadGame ? 
                                <span>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {` `} Loading...
                                </span>
                                :
                                <span>TAMPILKAN LEBIH BANYAK</span>
                            }
                        </Button>
                    </div>
                    :
                    <div></div>
                }
            </div>
            <div className="section_home secion_news">
                <p className="berita">BERITA DAN INFORMASI</p>
                <AllNews data={berita} />
                {
                    news_load ?
                    <div className="load_more_news_container">
                        <Button disabled={loadNews} onClick={()=>moreNews()} variant="purple">
                            {
                                loadNews ? 
                                <span>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {` `} Loading...
                                </span>
                                :
                                <span>LAINNYA</span>
                            }
                        </Button>
                    </div>
                    : <div></div>
                }
            </div>
        </div>
    )
}

export default Home;