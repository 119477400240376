import "../css/Topup.css"
import active1 from "../assets/check1.png"
import active2 from "../assets/check2.png"
import {Button,Container,Row,Col,FloatingLabel,Form,Image} from "react-bootstrap"
import { useHistory } from "react-router"
import { useCallback, useContext, useEffect, useState } from "react"
import Helper from "../Helper"
import { AppContext } from "../providers/AppContext"
import swal from '@sweetalert/with-react';
import LoadingScreen from "../components/LoadingScreen";


const Topup = (props) => {

    const history = useHistory()
    const currency = "Rp. ";
    const nominal = [
        {
            label:"20.000",
            val:20000
        },
        {
            label:"50.000",
            val:50000
        },
        {
            label:"100.000",
            val:100000
        },
        {
            label:"200.000",
            val:200000
        },
        {
            label:"300.000",
            val:300000
        },
        {
            label:"500.000",
            val:500000
        },
        {
            label:"1.000.000",
            val:1000000
        }
    ]

    const Payment = (props) => {
        const {data,index} = props
        const active = props.active ? "active" : ""
        data.price = data.price === undefined ? 0 : data.price
        return(
            <div 
                className={"payment_container "+active}
                onClick={()=>setActiveMethod(index)} 
            >
                {
                    active ?
                    <img className="active_icon" src={active2} alt="active" />
                    :
                    <div></div>
                }
                <div className="info">
                    <img className="payment_icon" src={data.logo} alt="payment_icon" />
                    <span>{data.name}</span>
                </div>
                <p>Rp. {data.price_text}</p>
            </div>
        )
    }

    const compare = ( a, b ) => {
        if ( a.price < b.price ){
          return -1;
        }
        if ( a.price > b.price ){
          return 1;
        }
        return 0;
    }

    const{DetailData,TopupWallet} = useContext(AppContext)

    const [amounts,setNominal] = useState("0")
    const [payment_opt,setPaymentOpt] = useState([])

    const [ads,setAds] = useState("https://via.placeholder.com/800x1000.png?text=-")
    const [config,setConfig] = useState({
        "id": 1,
        "space_iklan_topup": "https://via.placeholder.com/800x1000.png?text=Space%20Iklan",
        "cs_phone": "082255985321",
        "cs_email": "heru.m175@gmail.com",
        "gplay": "https://kalbargamestore.com",
        "appstore": "https://kalbargamestore.com",
        "others": "",
        "created_at": null,
        "updated_at": null
    })

    const [loadingPage,setLoadingPage] = useState(true)


    const fetchData = useCallback(async ()=>{
        let trx = localStorage.getItem("last_trx");
        if(trx !== undefined && trx !== null) {
            trx = JSON.parse(trx);
            return history.push("/status/"+trx.trx_id)
        }
        setLoadingPage(true)
        const result = await DetailData({})
        setLoadingPage(false)
        if(!result.status) return swal("","check your connection","info")
        setAds(result.data.config.space_iklan_topup)
        setConfig(result.data.config)
        setPaymentOpt(result.data.payment)
    },[DetailData,history])

    useEffect(()=>{
        fetchData()
    },[fetchData])


    const loadHarga = (harga) => {
        const new_payment_opt = payment_opt.map((item,index)=>{
            let price = parseInt(harga);
            const percent = price * parseFloat(item.fee_percent) ;
            const fee = parseInt(item.fee);
            price = price + fee + percent;
            // price = Math.ceil(price/100);
            item.price = price;
            price = Helper.thousands_separators(price);
            item.price_text = price;
            return item;
        })
        new_payment_opt.sort(compare)
        setPaymentOpt(new_payment_opt)
    }

    const [activeMethod,setActiveMethod] = useState(null)

    const submit = async () => {
        const payment = payment_opt[activeMethod];
        if(payment === undefined || activeMethod === null) return swal("","Silahkan pilih metode pembayaran","info")
        const regex = /[^0-9]+/g
        let val = amounts;
        val = val.replace( regex, "")
        const json = {
            payment_id:payment.id,
            nominal:val
        }
        const result = await TopupWallet(json)
        if(!result.status) return swal("","check your connection","info")
        if(!result.data.status) return swal("",result.data.message,"info")
        const last_trx = {trx_id:result.data.trx_id,via:payment.id,url:result.data.url}
        localStorage.setItem("last_trx",JSON.stringify(last_trx))
        history.push(result.data.url)
    }

    if(loadingPage)
    return(<LoadingScreen />)

    return(
        <Container fluid>
            <Row>
                <Col md={8}>
                    <div className="section mb-3">
                        <h3>TOP UP SALDO</h3>
                        <div className="topup_section">
                            <div className="step first_step">
                                <div className="step_icon">1</div>
                                <p className="step_name">Masukan nominal yang akan di TOP UP</p>
                                <div className="choose_nominal">
                                    {
                                        nominal.map((item,index)=>{
                                            const active = ""
                                            return(
                                                <div 
                                                    key={"nominal_"+index} 
                                                    className={"nominal "+active}
                                                    onClick={()=>{
                                                        let val = Helper.thousands_separators(item.val)
                                                        const regex = /[^0-9]+/g
                                                        let value = val.replace( regex, "")
                                                        loadHarga(value)
                                                        setNominal(val)
                                                    }}
                                                >
                                                    {
                                                        active ?
                                                        <img className="active_icon" src={active1} alt="active" />
                                                        :
                                                        <div></div>
                                                    }
                                                    {currency} {item.label}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <FloatingLabel
                                    label="Masukan nominal"
                                >
                                    <Form.Control 
                                        placeholder="0000"
                                        value={amounts}
                                        onChange={(e)=>{
                                            let val = e.target.value
                                            if(val === "") val = 0
                                            val = Helper.number_format(val)
                                            setNominal(val)
                                            const regex = /[^0-9]+/g
                                            let value = val.replace( regex, "")
                                            loadHarga(value)
                                        }}
                                    />
                                </FloatingLabel>
                            </div>
                            <div className="step last_step">
                                <div className="step_icon">2</div>
                                <p className="step_name">Pilih Metode Pembayaran</p>
                                <div className="payment_list">
                                    {
                                        payment_opt.map((item,index)=>{
                                            return(
                                                <Payment
                                                    active={index === activeMethod}
                                                    key={"payment_"+index.toString()}
                                                    data={item} 
                                                    index={index}
                                                />
                                            )
                                        })
                                    }
                                </div>
                                <div className="d-grid btn_submit">
                                    <Button onClick={()=>submit()} size="lg" variant="success">LANJUTKAN</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={4} className="ads_space my-3">
                    <Image
                        fluid
                        className="mb-2"
                        alt="space iklan"
                        src={ads}
                    />
                    <p className="text-center">
                    Ingin menjadi reseller resmi KALBAR GAME STORE ?
                    Hubungi  <a href={"tel:"+config.cs_phone}>Customer Service</a> kami atau <a href={"mailto:"+config.cs_email}>email</a>.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default Topup