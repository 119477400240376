import React, {createContext, useState} from "react"
import Helper from "../Helper"

const AppContext = createContext()

const AppProvider = (props) => {

    const [login,setLogin] = useState(false)
    const [wallet,setWallet] = useState(0)

    const HomeData = async () => {
        const result = await Helper.connecting("/homepage",{})
        return result
    }
    const DetailData = async (data) => {
        const result = await Helper.connecting("/detailpage",data)
        return result
    }
    const SubmitTransaction = async (data) => {
        const result = await Helper.connecting("/transaction",data)
        return result
    }
    const StatusData = async (data) => {
        const result = await Helper.connecting("/statuspage",data)
        return result
    }
    const CancelOrder = async (data) => {
        const result = await Helper.connecting("/cancelorder",data)
        return result
    }

    const LoginData = async (data) => {
        const result = await Helper.connecting("/login",data)
        return result
    }
    const OtpData = async (data) => {
        const result = await Helper.connecting("/otp",data)
        return result
    }
    const ProfileData = async (data) => {
        const result = await Helper.connecting("/profile",{})
        return result
    }
    const UpdateProfile = async (data) => {
        const result = await Helper.connecting("/profile-update",data)
        return result
    }
    const History = async (data) => {
        const result = await Helper.connecting("/history",data)
        return result
    }
    const TopupWallet = async (data) => {
        const result = await Helper.connecting("/topup",data)
        return result
    }
    const NewsData = async (data) => {
        const result = await Helper.connecting("/newspage",data)
        return result
    }
    const RegisterData = async (data) => {
        const result = await Helper.connecting("/register",data)
        return result
    }
    const ForgotData = async (data) => {
        const result = await Helper.connecting("/forgot-password",data)
        return result
    }
    const ResetPassData = async (data) => {
        const result = await Helper.connecting("/reset-password",data)
        return result
    }
    const ResendOtp = async (data) => {
        const result = await Helper.connecting("/resend-otp",data)
        return result
    }
    const GameData = async (data) => {
        const result = await Helper.connecting("/games",data)
        return result
    }
    const FormSaver = async (data) => {
        const result = await Helper.connecting("/form-saver",data)
        return result
    }

    const values = {
        GameData,
        ResendOtp,
        ResetPassData,
        ForgotData,
        RegisterData,
        NewsData,
        HomeData,
        DetailData,
        SubmitTransaction,
        StatusData,
        CancelOrder,
        LoginData,
        OtpData,
        ProfileData,
        UpdateProfile,
        History,
        TopupWallet,
        FormSaver,

        login,setLogin,
        wallet,setWallet,
    }
    return(
        <AppContext.Provider value={values}>{props.children}</AppContext.Provider>
    )
}

export { AppProvider, AppContext }
// export const useCtx = () => useContext(AppContext);