import "../css/Otp.css"
import {Button,Form,Spinner} from "react-bootstrap"
import { useHistory } from "react-router"
import { useContext, useEffect, useRef, useState } from "react"
import { AppContext } from "../providers/AppContext"
import swal from '@sweetalert/with-react'

const Otp = (props) => {
    const history = useHistory()
    const [destination,setDest] = useState("")
    const [user,setUser] = useState({})
    const {OtpData,setLogin,ResendOtp} = useContext(AppContext)

    useEffect(()=>{

        let userData = localStorage.getItem("user")
        if(userData !== undefined && userData !== null) 
        return history.push("/profile")

        let user = localStorage.getItem("login")
        if(user === undefined || user === null) 
        return history.push("/login")

        user = JSON.parse(user)
        setUser(user)
        setDest(user.email)

        document.title = "OTP - Game Store"

    },[history])

    const [validated, setValidated] = useState(false)
    const [loading,setLoading] = useState(false)


    const submit = async (event) => {
        const form = event.currentTarget;
        const formData = new FormData(form);
        event.preventDefault();
        setValidated(false)
        let confirmcode = ""
        for (let [key, value] of formData.entries()) {
            confirmcode += value;
            console.log(key)
        }
        let input = user;
        input.confirm_code = confirmcode;
        setLoading(true)
        const result = await OtpData(input);
        setLoading(false)
        if(!result.status) return swal("","check your connection","info")
        if(!result.data.status) return swal("",result.data.message,"info")
        if(result.data.message === "OK") {
            localStorage.removeItem("login");
            localStorage.setItem("user",JSON.stringify(result.data.data))
            setLogin(true)
            history.push("/profile")
        }

    }

    const [show,setShow] = useState(false)

    const intervalID = useRef()

    const [time,setTime] = useState("00:00")

    const startTimer = (duration) => {
        var timer = duration, minutes, seconds;
        setShow(true)
        intervalID.current =  setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            setTime(minutes + ":" + seconds);

            if (--timer < 0) {
                timer = duration;
                setShow(false)
                clearInterval(intervalID.current);
            }
        }, 1000);
    }

    // const duration = 60*2;
    const duration = 10;
    useEffect(()=>{
        if(intervalID.current !== null) startTimer(duration);
    },[])

    const resend = async (type) => {
        const data = {type:type,email:user.email};
        const result = await ResendOtp(data);
        if(!result.status) return swal("","check your connection","info")
        if(!result.data.status) return swal("",result.data.message,"info")
        swal("",result.data.message,"info")
        setDest(result.data.destination)
        if(intervalID.current !== null) startTimer(duration);
    }

    // const numOfFields = 3;
    const handleChange = e => {
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        console.log(fieldName)
      
        // Check if they hit the max character length
        if (value.length >= maxLength) {
          // Check if it's not the last input field
        //   if (parseInt(fieldIndex, 10) < 3) {
            // Get the next input field
            const nextSibling = document.querySelector(
              `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
            );
      
            // If found, focus the next field
            if (nextSibling !== null) {
              nextSibling.focus();
            }
        //   }
        }
    }
    
    return (
        <div className="container_status">
            <Form noValidate validated={validated} onSubmit={submit}>
                <div className="content_status_otp">
                    <p className="status">Menunggu Validasi</p>
                    <p>Cek pesan anda, kode dikirimkan ke {destination}</p>
                    <div className="otp_field">
                        <Form.Group>
                            <Form.Control 
                                maxLength="1"
                                name="ssn-1"
                                required
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control 
                                maxLength="1"
                                name="ssn-2"
                                required
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control 
                                maxLength="1"
                                name="ssn-3"
                                required
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control 
                                maxLength="1"
                                name="ssn-4"
                                required
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control 
                                maxLength="1"
                                name="ssn-5"
                                required
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control 
                                maxLength="1"
                                name="ssn-6"
                                required
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="full_width text-center">
                        <Button 
                            disabled={loading}
                            type="submit"
                            className="full_width" 
                            variant="success"
                        >
                            {
                                loading ? 
                                <span>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {` `} Loading...
                                </span>
                                :
                                <span>KONFIRMASI</span>
                            }
                        </Button>
                        <Button 
                            onClick={()=>{
                                localStorage.removeItem("login")
                                history.push("/login")
                            }}
                            type="button"
                            className="full_width mt-3" 
                            variant="danger"
                        >BATALKAN</Button>
                    </div>
                    {
                        !show ?
                            <p className="text-center mt-5 info">Tidak menerima pesan ? <br/>
                            Kirim ulang kode OTP melalui pesan <a href={()=>false} onClick={(e)=>resend("WA")}>sms</a> atau <a href={()=>false} onClick={(e)=>resend("email")}>email</a></p>
                        :
                        <p className="mt-5">Tunggu untuk mengirim ulang, {time}</p>
                    }
                </div>
            </Form>
        </div>
    )
}

export default Otp