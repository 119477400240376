const public_url = "https://kalbargamestore.com"
const server_url = "https://server.kalbargamestore.com"
// const server_url = "http://server-kalbargamestore.test"
const main_url = server_url+"/api"

const stringToAngka = (value) => {
    return value.match( /\d+/g ).join('');
}

const connecting = async (path,json) => {

    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
    let user = localStorage.getItem("user");

    let token = "";
    if(user !== undefined && user !== null){
        user = JSON.parse(user);
        token = user.token;
    }
    header.Authorization = "Bearer "+token;

    let url = main_url+path;

    return await fetch(url, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(json),
    })
    .then((response) => response.json())
    .then((responseJson) => {
        // console.log(responseJson);
        return {status:true,data:responseJson};
    })
    .catch((error) => {
        // console.log("error",error);
        return {status:false,data:null,error:error};

    });
}

const thousands_separators = (num) => {
    var num_parts = num.toString().split(",");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return num_parts.join(",");
}

const number_format = (value) => { 
    const regex = /[^0-9]+/g;
    value = value.toString().replace( regex, "");
    value = value.split( /(?=(?:...)*$)/ );
    value = value.join(".");
    return value;
}

const Helper = {stringToAngka,connecting,thousands_separators,main_url,server_url,number_format,public_url}

export default Helper;