import "../css/Register.css"
import {Button,FloatingLabel,Form,Spinner} from "react-bootstrap"
import { useHistory } from "react-router"
import {useState,useContext,useEffect,useCallback} from "react"
import {AppContext} from "../providers/AppContext"
import swal from '@sweetalert/with-react'


const Register = (props) => {
    const history = useHistory()
    const [validated,setValidated] = useState(false)

    const {DetailData,RegisterData} = useContext(AppContext)

    const [config,setConfig] = useState({
        "id": 1,
        "space_iklan_topup": "https://via.placeholder.com/800x1000.png?text=Space%20Iklan",
        "cs_phone": "082255985321",
        "cs_email": "heru.m175@gmail.com",
        "gplay": "https://kalbargamestore.com",
        "appstore": "https://kalbargamestore.com",
        "others": "",
        "created_at": null,
        "updated_at": null
    })

    const fetchData = useCallback(async ()=>{
        const result = await DetailData({})
        if(!result.status) return swal("","check your connection","info")
        setConfig(result.data.config)
    },[DetailData])

    useEffect(()=>{
        document.title = "Register - Game Store"
        fetchData()
    },[fetchData])

    const [loading,setLoading] = useState(false)

    const submit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        if(!form.checkValidity()) {
            return;
        }
        const formData = new FormData(form);
        let input = {}
        for (let [key, value] of formData.entries()) {
            input[key] = value;
        }
        setLoading(true)
        const result = await RegisterData(input);
        setLoading(false)
        if(!result.status) return swal("","check your connection","info")
        if(!result.data.status) return swal("",result.data.message,"info")
        const {data} = result.data;
        const user = {"email":data.email,"password":data.password}
        localStorage.setItem("login",JSON.stringify(user));
        history.push("/otp")

    }
    return (
        <div className="container_box">
            <div className="transbox">
                <Form noValidate validated={validated} onSubmit={submit}>
                    <div className="content_status_box">
                        <p className="head">Daftar Sekarang</p>
                        <FloatingLabel
                            label="Nama Lengkap"
                        >
                            <Form.Control 
                                placeholder="0000"
                                required
                                name="name"
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            label="No. HP"
                        >
                            <Form.Control 
                                placeholder="0000"
                                required
                                name="no_hp"
                                type="number"
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            label="Email"
                        >
                            <Form.Control 
                                placeholder="0000"
                                required
                                name="email"
                                type="email"
                            />
                            <Form.Control.Feedback type="invalid">Periksa kembali format email</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                            label="Password"
                        >
                            <Form.Control 
                                placeholder="0000"
                                required
                                name="password"
                                type="password"
                                minlength="8"
                            />
                            <Form.Control.Feedback type="invalid">Minimal 8 karakter</Form.Control.Feedback>
                        </FloatingLabel>
                        <Button disabled={loading} type="submit" variant="purple" className="log_btn">
                        {
                            loading ? 
                            <span>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                {` `} Loading...
                            </span>
                            :
                            <span>DAFTAR SEKARANG</span>
                        }
                        </Button>
                        <p>Sudah punya akun? <a href={()=>false} onClick={()=>history.push("/login")}>Masuk Sekarang</a></p>
                        <p className="text-center">
                            Ingin menjadi reseller resmi KALBAR GAME STORE ? <br /> 
                            Hubungi <a href={"tel:"+config.cs_phone}>Customer Service</a> kami atau <a href={"mailto:"+config.cs_email}>email</a>
                        </p>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default Register