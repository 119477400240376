import React from "react";
import Lottie from "lottie-react";
import Loading from "../assets/loading.json"
const LoadingScreen = props => {
    return(
        <div className="loading_container">
            <div className="loading_animation">
                <Lottie 
                    animationData={Loading} 
                    loop={true} 
                    
                />
            </div>
            <h1 className="text-light text-center">Sedang memuat ...</h1>
        </div>
    )
}

export default LoadingScreen