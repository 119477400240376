import "../css/Login.css"
import {Button,FloatingLabel,Form,Spinner} from "react-bootstrap"
import { useHistory } from "react-router"
import {useState,useContext,useCallback,useEffect} from "react"
import {AppContext} from "../providers/AppContext"
import swal from '@sweetalert/with-react';
const ForgotPassword = (props) => {

    const history = useHistory()

    const [email,setEmail] = useState("")

    const {DetailData,ForgotData} = useContext(AppContext)


    const [config,setConfig] = useState({
        "id": 1,
        "space_iklan_topup": "https://via.placeholder.com/800x1000.png?text=Space%20Iklan",
        "cs_phone": "+6289693242523",
        "cs_email": "kalbarwebsite607@gmail.com",
        "gplay": "https://kalbargamestore.com",
        "appstore": "https://kalbargamestore.com",
        "others": "",
        "created_at": null,
        "updated_at": null
    })

    const fetchData = useCallback(async ()=>{
        const result = await DetailData({})
        if(!result.status) return swal("","check your connection","info")
        setConfig(result.data.config)
    },[DetailData])

    useEffect(()=>{
        document.title = "Forgot Password - Game Store"
        fetchData()
    },[fetchData])

    const [validated, setValidated] = useState(false)
    const [loading,setLoading] = useState(false)

    const submit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true)
        if(!form.checkValidity()) {
            return;
        }
        setLoading(true)
        const result = await ForgotData({email:email});
        setLoading(false)
        if(!result.status) return swal("","check your connection","info");
        swal("",result.data.message,"success");
    }

    return (
        <div className="container_box">
            <div className="transbox">
            <Form noValidate validated={validated} onSubmit={submit}>
                    <div className="content_status_box">
                        <p className="head">Kami akan mengirim link ke email anda</p>
                        <FloatingLabel
                            label="Email"
                        >
                            <Form.Control 
                                placeholder="0000"
                                required
                                name="email"
                                type="email"
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">Please fill this required, check your email format</Form.Control.Feedback>
                        </FloatingLabel>
                        <Button disabled={loading} type="submit" variant="purple" className="log_btn">
                            {
                                loading ? 
                                <span>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {` `} Loading...
                                </span>
                                :
                                <span>KIRIM</span>
                            }
                        </Button>
                        <p>Belum punya akun? <a href={() => false} onClick={()=>history.push("/register")} >Daftar Sekarang</a></p>
                        <p className="text-center">
                            Ingin menjadi reseller resmi KALBAR GAME STORE ? <br /> 
                            Hubungi <a href={"tel:"+config.cs_phone}>Customer Service</a> kami atau <a href={"mailto:"+config.cs_email}>email</a>
                        </p>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default ForgotPassword