import "../css/Profile.css"
import {Container,Row,Col,Button,Badge,Table,Spinner} from "react-bootstrap"

import coin from "../assets/coin.png"
import pencil from "../assets/pencil.png"
import save from "../assets/save.png"
import searchLogo from "../assets/loupe.png"
import { useHistory } from "react-router"
import { useCallback, useContext, useEffect,useState,useRef } from "react"
import { AppContext } from "../providers/AppContext"
import Helper from "../Helper"
import LoadingScreen from "../components/LoadingScreen"

import swal from '@sweetalert/with-react'



const Profile = (props) => {

    const history = useHistory()

    const {ProfileData,wallet,setWallet,
        UpdateProfile, History
    } = useContext(AppContext)

    const [profile_picture,setProfilePict] = useState("/profile_pict.png")
    const [profile,setProfile] = useState({
        name:"-",
        role:"-",
        created_at:"01-01-2021 00:00:00"
    })

    const [historyData,setHistory] = useState([])
    const [total_history,setTotalHistory] = useState([])
    const [loadingPage,setLoadingPage] = useState(true)

    const fetchData = useCallback(async () => {
        setLoadingPage(true)
        const result = await ProfileData();
        setLoadingPage(false)
        if(!result.status) return swal("","check your connection","info")
        setProfilePict(result.data.profile_photo_url)
        localStorage.setItem("profile_pict",result.data.profile_photo_url)
        setProfile(result.data)
        setName(result.data.name)
        setEmail(result.data.email)
        setNohp(result.data.no_hp)
        setWallet(result.data.wallet)
        document.title = "Profil Saya - Game Store"
        setHistory(result.data.history)
        setTotalHistory(result.data.total_history)
        setSortBy(result.data.sorting_by)
    },[ProfileData,setWallet])

    useEffect(()=>{
        const userData = localStorage.getItem("user")
        if(userData === undefined || userData === null) return history.push("/login")
        fetchData()
    },[history,fetchData])

    const Input = (props) => {
        const icon = props.disabled ? pencil : save
        const [val,setVal] = useState("")
        useEffect(()=>{
            setVal(props.value)
        },[props])
        return(
            <div className="edit_input">
                <span>{props.label}</span>
                <div className="input">
                    <input 
                        name={props.name}
                        disabled={props.disabled}
                        type={props.type}
                        placeholder={props.placeholder} 
                        value={val}
                        onChange={(e)=>setVal(e.target.value)}
                    />
                    <img
                        className="editor_icon"
                        onClick={async ()=>{
                            if(!props.disabled) {
                                let update ={}
                                update[props.name] = val;
                                props.onChange(val)
                                const result = await UpdateProfile(update)
                                if(!result.status) return swal("","check your connection","info")
                            }
                            props.onActivated()
                        }}
                        alt={"edit "+props.placeholder}
                        src={icon}
                    />
                </div>
            </div>
        )
    }

    const [activeName,setActiveName] = useState(true)
    const [activeEmail,setActiveEmail] = useState(true)
    const [activeNohp,setActiveNohp] = useState(true)

    const [name,setName] = useState("")
    const [email,setEmail] = useState("")
    const [no_hp,setNohp] = useState("")

    const onFileUpload = (data) => {
        // console.log(data);
        const formData = new FormData();
        formData.append(
            "file_content",
            data,
            data.name
        );
        const url = Helper.main_url+"/upload_image";
        let user = localStorage.getItem("user");
        let token = "";
        let header = {};
        if(user !== undefined && user !== null){
            user = JSON.parse(user);
            token = user.token;
        }
        header.Authorization = "Bearer "+token;
        fetch(url,{
            method:"POST",
            body:formData,
            headers:header
        })
        .then((response) => response.json())
        .then(async (responseJson) => {
            file_dom.current.value = ""
            const path = Helper.server_url+"/storage"+responseJson.data;
            localStorage.setItem("profile_pict",path);
            setProfilePict(path)
            let update = {}
            update.profile_photo_path = responseJson.data;
            const result = await UpdateProfile(update)
            if(!result.status) return swal("","check your connection","info")
        })
        .catch((error) => {
            console.log("error",error);

    });
    }

    const file_dom = useRef()

    const [sort,setSort] = useState("desc")
    const [sortBy,setSortBy] = useState([])
    const [load,setLoad] = useState(false)
    const [btn_load,setButtonLoad] = useState(true)
    const getHistory = async (type) => {
        const last_index = historyData.length -1;
        const data = historyData[last_index];
        const json = {
            last_id:data.id,
            orderBy:sort,
            search:search
        }
        setLoad(true)
        const result = await History(json);
        setLoad(false)
        if(!result.status) return swal("","check your connection","info")
        let new_list = historyData;
        
        setButtonLoad(result.data.list.length !== 0)

        new_list = new_list.concat(result.data.list)
        setHistory(new_list)
        setTotalHistory(result.data.total)
    }

    const filterData = useCallback(async() => {
        const json = {orderBy:sort}
        // console.log(json)
        const result = await History(json);
        if(!result.status) return swal("","check your connection","info")
        setHistory(result.data.list)
        setTotalHistory(result.data.total)
    },[History,sort])

    useEffect(()=>{
        filterData()
    },[sort,filterData])

    const [search,setSearch] = useState("")

    const searching = async () => {
        const json = {orderBy:sort,search:search}
        const result = await History(json);
        if(!result.status) return swal("","check your connection","info")
        setHistory(result.data.list)
        setTotalHistory(result.data.total)
    }

    if(loadingPage)
    return(<LoadingScreen />)

    return(
        <Container fluid>
            <Row>
                <Col md={4} className="detail_profile">
                    <input 
                        ref={file_dom}
                        accept="image/*"
                        id="upload_image" 
                        type="file" 
                        name="upload_image" 
                        className="hidden"
                        onChange={ event => {
                            // setFile(event.target.files[0])
                            onFileUpload(event.target.files[0])
                        }}
                    />
                    <img 
                        alt="profile avatar"
                        className="profile_image"
                        src={profile_picture}
                        onClick={()=>{
                            // console.log(file_dom)
                            file_dom.current.click()
                        }}
                    />
                    <p className="name">{name}</p>
                    <p className="role">{profile.role.toUpperCase()}</p>
                    <p className="since">{new Date(profile.created_at).toLocaleDateString()} {new Date(profile.created_at).toLocaleTimeString()}</p>
                    <Button onClick={()=>history.push("/topup")} className="topup" variant="purple" size="lg">TOP UP SALDO</Button>
                    <div className="amounts">
                        <img 
                            src={coin}
                            alt="coin logo" 
                        />
                        <span>Rp. {wallet}</span>
                    </div>
                    <div className="edit_profile">
                        <Input
                            label="Nama"
                            type="text"
                            disabled={activeName}
                            onActivated={()=>setActiveName(!activeName)}
                            placeholder="Nama Lengkap"
                            value={name}
                            name="name"
                            onChange={(v)=>setName(v)}
                        />
                        <Input
                            label="Email"
                            type="email"
                            disabled={activeEmail}
                            onActivated={()=>setActiveEmail(!activeEmail)}
                            placeholder="example@email.com"
                            value={email}
                            name="email"
                            onChange={(v)=>setEmail(v)}
                        />
                        <Input
                            label="No. HP"
                            type="number"
                            disabled={activeNohp}
                            onActivated={()=>setActiveNohp(!activeNohp)}
                            placeholder="+628123456xxx"
                            value={no_hp}
                            name="no_hp"
                            onChange={(v)=>setNohp(v)}
                        />
                    </div>
                </Col>
                <Col md={8}>
                    <div className="section">
                        <h3>Riwayat Transaksi</h3>
                        <div className="data_table">
                            <div className="table_filter">
                                <select 
                                    onChange={event=>setSort(event.target.value)}
                                >
                                    <option value="desc">Urutkan berdasarkan</option>
                                    {
                                        sortBy.map((item,index)=>{
                                            return(
                                                <option value={item.value}>{item.label}</option>
                                            )
                                        })
                                    }
                                </select>
                                <div className="search_player">
                                    <input 
                                        onChange={(e)=>setSearch(e.target.value)} 
                                        placeholder="Pencarian ID Player"
                                        onKeyDown={(e)=>{
                                            if (e.key === 'Enter') {
                                                searching()
                                            }
                                        }}
                                    />
                                    <img onClick={()=>searching()} src={searchLogo} alt="search" />
                                </div>
                            </div>
                        </div>
                        <p className="mt-3 mb-1">Total Records : {total_history}</p>
                        <Table responsive className="table_data">
                            <thead>
                                <tr>
                                    <th>ID Transaksi</th>
                                    <th>ID Player</th>
                                    <th>No. HP</th>
                                    <th>Tanggal</th>
                                    <th>Jumlah</th>
                                    <th>Keterangan</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    historyData.map((item,index)=>{
                                        let status = "primary";
                                        if(item.status === "berhasil") status = "success";
                                        if(item.status === "batal") status = "danger";
                                        if(item.status === "proses") status = "warning";
                                        return(
                                            <tr key={"rows_"+index.toString()}>
                                                <td>
                                                    {/*
                                                        (item.type === "trx" && item.status === "pending") || 
                                                        (item.type === "topup" && item.status === "pending")
                                                        ?
                                                        <a href={()=>false} onClick={()=>history.push("/status/"+item.uid)}>{item.uid}</a>
                                                        : item.uid
                                                    */}
                                                    <a href={()=>false} onClick={()=>history.push("/status/"+item.uid)}>{item.uid}</a>
                                                </td>
                                                <td>{item.destination}</td>
                                                <td>{item.user.no_hp}</td>
                                                <td>{new Date(item.created_at).toLocaleDateString()} {new Date(item.created_at).toLocaleTimeString()}</td>
                                                <td>Rp. {Helper.thousands_separators(item.nominal)}</td>
                                                <td>
                                                {
                                                    item.type === "trx" ?
                                                    item.product.categoryproduct.game.name+" - "+item.product.name+" \n via "+item.via
                                                    : item.keterangan.toUpperCase()
                                                }
                                                </td>
                                                <td className="text-center">
                                                    <Badge bg={status}>{item.status.toUpperCase()}</Badge>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        {
                            btn_load ?
                            <div className="d-grid text-center">
                                <Button onClick={()=>getHistory()} size="md" variant="primary">
                                    {
                                        load ? 
                                        <span>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            {` `} Loading...
                                        </span>
                                        :
                                        <span>Load more</span>
                                    }
                                </Button>
                            </div>
                            : ""
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Profile