import "../css/News.css"
import { useParams,useHistory } from "react-router"
import { 
    Row, 
    Col,
    InputGroup,
    FormControl,
    Container,
    Button
} from "react-bootstrap"

import swal from '@sweetalert/with-react'

import fb from "../assets/fb.png";
import twitter from "../assets/twitter.png";
// import ig from "../assets/ig.png";

import wa from "../assets/wa.png";
import searchLogo from "../assets/loupe.png"
import { useCallback, useContext,useEffect,useState } from "react";
import { AppContext } from "../providers/AppContext";
import parse from 'html-react-parser';
import Helper from "../Helper";
import LoadingScreen from "../components/LoadingScreen";


const News = (props) => {

    const history = useHistory()

    const params = useParams()
    const shareUrl = Helper.public_url+"/news/"+params.slug

    const {NewsData} = useContext(AppContext);
    const [content,setContent] = useState({
        title:"-",
        description:"-",
        user:{name:"-"},
        created_at:"31/12/2021 00:00"
    })

    const [news_data,setNewsData] = useState([])
    const [loadingPage,setLoadingPage] = useState(true)

    const fetchData = useCallback(async()=>{
        const data = {slug:params.slug}
        setLoadingPage(true)
        const result = await NewsData(data)
        setLoadingPage(false)
        if(!result.status) return swal("","check your connection","info")
        setNewsData(result.data.list)
        if(result.data.news.length === 0) return swal("","berita tidak ditemukan","info")
        setContent(result.data.news)

        document.title = result.data.news.title.toUpperCase()+" - Kalbar Game Store"
        document.querySelector('meta[name="og:title"]').setAttribute("content", result.data.news.title.toUpperCase());

        let regex = /(<([^>]+)>)/ig
        , max = 300
        ,   desc = result.data.news.description.replace(regex, "");
        if(desc.length > max) desc = desc.substring(0,max);
        document.querySelector('meta[name="description"]').setAttribute("content", desc);
        document.querySelector('meta[name="og:description"]').setAttribute("content", desc);

        const images = result.data.news.images === null ? "/news.jpeg" : result.data.news.images;
        document.querySelector('meta[name="og:image"]').setAttribute("content", images);

    },[params,NewsData])

    useEffect(()=>{
        fetchData()
    },[fetchData])

    const shareTo = [
        {
            "name":"Facebook",
            "icon":fb,
            "base_url":"https://www.facebook.com/sharer/sharer.php?u="
        },
        {
            "name":"Twitter",
            "icon":twitter,
            "base_url":"http://twitter.com/share?url="
        },
        {
            "name":"Whatsapp",
            "icon":wa,
            "base_url":"https://wa.me?text="
        },
    ]

    const ShareLink = ({data}) => {
        return(
            <a href={data.base_url+shareUrl}>
                <img src={data.icon} alt={data.name} />
            </a>
        )
    }

    const NewsItem = (props) => {
        return(
            <div className="news_content" onClick={()=>history.push("/news/"+props.slug)}>
                <img
                    alt={props.title+" - News Games Store"}
                    src={props.image} 
                />
                <div className="news_info">
                    <h3>{props.title}</h3>
                    <p>{props.description}</p>
                    <a onClick={()=>history.push("/news/"+props.slug)} role="button" href={()=>false}>Baca selengkapnya</a>
                </div>
            </div>
        )
    }

    const [search,setSearch] = useState()

    const searching = async () => {
        const data = {search:search}
        const result = await NewsData(data)
        if(!result.status) return swal("","check your connection","info")
        setNewsData(result.data.list)
    }

    const loadMore = async () => {
        const last = news_data[news_data.length-1];
        if(last === undefined) return swal("","not found load more","info")
        const data = {search:search,last_id:last.id}
        const result = await NewsData(data)
        if(!result.status) return swal("","check your connection","info")
        let new_list = [...news_data,...result.data.list]
        setNewsData(new_list)
    }

    if(loadingPage)
    return(<LoadingScreen />)

    return(
        <Container fluid className="news_container">
            <Row>
                <Col md={6}>
                    <div className="news">
                        <h3>{content.title}</h3>
                        <p className="details">Penulis : <span>{content.user.name}</span> | diterbitkan pada : {new Date(content.created_at).toLocaleString()}</p>
                        {parse(content.description)}
                        <p className="cta_share">Suka dengan artikel ini? Bagikan sekarang!</p>
                        <div className="share_icon_list">
                            {shareTo.map((item,index)=>{
                                return(<ShareLink data={item} key={index.toString()} />)
                            })}
                        </div>
                    </div>
                </Col>
                <Col md={6} className="timeline_list">
                    <div className="timeline_container">
                        <div className="timeline_header">
                            <p className="head_text">Berita & Informasi</p>
                            <div className="search_container_news">
                                <div className="search_news">
                                    <InputGroup className="search_container">
                                        <FormControl
                                            placeholder="Pencarian berita..."
                                            className="search"
                                            value={search}
                                            onChange={(e)=>setSearch(e.target.value)}
                                            onKeyDown={(e)=>{
                                                if (e.key === 'Enter') {
                                                    searching()
                                                }
                                            }}
                                        />
                                        <InputGroup.Text>
                                            <img src={searchLogo} alt="search_logo" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                        <div className="search_news_mobile">
                            <InputGroup>
                                <FormControl
                                    placeholder="Pencarian berita..."
                                    className="search"
                                    value={search}
                                    onChange={(e)=>setSearch(e.target.value)}
                                    onKeyDown={(e)=>{
                                        if (e.key === 'Enter') {
                                            searching()
                                        }
                                    }}
                                />
                                <InputGroup.Text>
                                    <img src={searchLogo} alt="search_logo" />
                                </InputGroup.Text>
                            </InputGroup>
                        </div>      
                        <div className="news_timeline">
                            {
                                news_data.length === 0 ?
                                <div className="news_empty">
                                    News empty or not found...
                                </div>
                                :
                                <div></div>
                            }
                            {news_data.map((item,index)=>{
                                let regex = /(<([^>]+)>)/ig
                                , max = 150
                                ,   result = item.description.replace(regex, "");
                                if(result.length > max) result = result.substring(0,max);
                                item.images = item.images === null ? "/news.jpeg" : item.images;
                                return(
                                    <NewsItem 
                                        key={"news_"+index.toString()}
                                        image={item.images} 
                                        title={item.title} 
                                        description={result} 
                                        slug={item.slug} 
                                    />
                                )
                            })}
                            {
                                news_data.length >= 5 ? 
                                <div className="d-grid text-center p-3">
                                    <Button onClick={()=>loadMore()} variant="primary" size="md">Load More</Button>
                                </div>
                                :
                                <div></div>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default News