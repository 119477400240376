import "../css/Page.css"
import {useParams} from "react-router-dom"
import { useCallback,useState,useContext,useEffect } from "react"
import parse from 'html-react-parser'
import { AppContext } from "../providers/AppContext"
import swal from '@sweetalert/with-react'
import LoadingScreen from "../components/LoadingScreen"

const Page = (props) => {

    const params = useParams()

    const {NewsData} = useContext(AppContext);
    const [content,setContent] = useState({
        title:"-",
        description:"-",
        user:{name:"-"},
        created_at:"31/12/2021 00:00"
    })
    const [loadingPage,setLoadingPage] = useState(true)

    const fetchData = useCallback(async()=>{
        const data = {slug:params.slug}
        setLoadingPage(true)
        const result = await NewsData(data)
        setLoadingPage(false)
        if(!result.status) return swal("","check your connection","info")
        if(result.data.news.length === 0) return swal("","news not found","info")
        setContent(result.data.news)

        document.title = result.data.news.title.toUpperCase()+" - Kalbar Game Store"
        document.querySelector('meta[name="og:title"]').setAttribute("content", result.data.news.title.toUpperCase());

        let regex = /(<([^>]+)>)/ig
        , max = 300
        ,   desc = result.data.news.description.replace(regex, "");
        if(desc.length > max) desc = desc.substring(0,max);
        document.querySelector('meta[name="description"]').setAttribute("content", desc);
        document.querySelector('meta[name="og:description"]').setAttribute("content", desc);

        const images = result.data.news.images === null ? "/news.jpeg" : result.data.news.images;
        document.querySelector('meta[name="og:image"]').setAttribute("content", images);

    },[params,NewsData])

    useEffect(()=>{
        fetchData()
    },[fetchData])

    if(loadingPage)
    return(<LoadingScreen />)

    return(
        <div className="content">
            <h3 className="text-center">{content.title}</h3>
            {parse(content.description)}
        </div>
    )
}

export default Page;