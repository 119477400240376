import "../css/Login.css"
import {Button,FloatingLabel,Form} from "react-bootstrap"
import { useHistory,useParams } from "react-router"
import {useContext,useState} from "react"
import { AppContext } from "../providers/AppContext"
import swal from '@sweetalert/with-react';

const SetNewPassword = (props) => {
    const history = useHistory()
    const params = useParams()
    const {ResetPassData} = useContext(AppContext)
    const [validated, setValidated] = useState(false);

    const submit = async (event) => {
        const form = event.currentTarget;
        const formData = new FormData(form);
        event.preventDefault();
        setValidated(true)
        if(!form.checkValidity()) {
            return;
        }
        let input = {}
        for (let [key, value] of formData.entries()) {
            input[key] = value;
        }
        const result = await ResetPassData(input);
        if(!result.status) return swal("","check your connection","info")
        if(!result.data.status) return swal("",result.data.message,"info")
        swal("",result.data.message,"success")
        history.push("/login")
    }
    return (
        <div className="container_box">
            <div className="transbox">
                <Form noValidate validated={validated} onSubmit={submit}>
                    <input type="hidden" value={params.slug} name="token" />
                    <div className="content_status_box">
                        <p className="head">Silahkan masukan password baru anda</p>
                        <FloatingLabel
                            label="Email"
                        >
                            <Form.Control 
                                placeholder="0000"
                                type="email"
                                required
                                name="email"
                            />
                            <Form.Control.Feedback type="invalid">Please fill this field, check email format</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                            label="Password Baru"
                        >
                            <Form.Control 
                                placeholder="0000"
                                type="password"
                                minLength="8"
                                required
                                name="password"
                            />
                            <Form.Control.Feedback type="invalid">Please fill this field, minimum 8 charater</Form.Control.Feedback>
                        </FloatingLabel>
                        <Button type="submit" variant="purple" className="log_btn">UBAH DATA</Button>
                        <p>Belum punya akun? <a href={() => false} onClick={()=>history.push("/register")} >Daftar Sekarang</a></p>
                        <p className="text-center">
                            Ingin menjadi reseller resmi KALBAR GAME STORE ? <br /> 
                            Hubungi  <a href="//google.com">Customer Service</a> kami atau <a href="//google.com">email</a>
                        </p>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default SetNewPassword